<template>
  <div class="fangdai">
    <van-nav-bar title="房产抵押贷款申请"
                 left-text="返回"
                 left-arrow
                 @click-left="$router.go(-1)"
                 :border="true" />
    <van-form @submit="onSubmit">
      <van-field input-align="right"
                 v-model="formData.Name"
                 required
                 name="name"
                 label="申请人"
                 placeholder="申请人"
                 :rules="[{ required: true, message: '请填写申请人' },{ validator:nameValidator, message: '请输入申请人姓名应为2-5个汉字' }]" />
      <van-field input-align="right"
                 v-model="formData.Mobile"
                 required
                 name="Mobile"
                 type="tel"
                 label="手机号码"
                 placeholder="手机号码"
                 :rules="[{ required: true, message: '请填写手机号码' },{ validator:mobileValidator, message: '请输入手机号码' }]" />
      <van-field input-align="right"
                 :value="streetText"
                 readonly
                 clickable
                 required
                 name="street"
                 label="所在小区"
                 placeholder="点击选择所在小区"
                 @click="showPickerStreet = true"
                 :rules="[{ required: true, message: '选择所在小区' }]">
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>
      <van-popup v-model="showPickerStreet"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="columnsStreet"
                    @confirm="onstreetConfirm"
                    @cancel="showPickerStreet = false" />
      </van-popup>
      <van-field input-align="right"
                 :value="dotText"
                 readonly
                 clickable
                 required
                 name="DotId"
                 label="受理网点"
                 placeholder="点击选择受理网点"
                 @click="dotShowPicker = true"
                 :rules="[{ required: true, message: '请选择受理网点' }]">
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>
      <van-popup v-model="dotShowPicker"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="dotColumns"
                    @confirm="dotConfirm"
                    @cancel="dotShowPicker = false" />
      </van-popup>
      <van-field input-align="right"
                 :value="sysUserText"
                 readonly
                 clickable
                 required
                 name="SysUserId"
                 label="客户经理"
                 placeholder="点击选择客户经理"
                 @click="sysUserShowPicker = true"
                 :rules="[{ required: true, message: '请选择客户经理' }]">
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>
      <van-popup v-model="sysUserShowPicker"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="sysUserColumns"
                    @confirm="sysUserConfirm"
                    @cancel="sysUserShowPicker = false" />
      </van-popup>
      <van-field input-align="right"
                 :value="position"
                 readonly
                 name="position"
                 label="网点位置"
                 placeholder="网点位置">
      </van-field>

      <!--            <div style="margin: 16px;">-->
      <!--                <van-checkbox v-model="aggree" shape="square" style="font-size: 12px" icon-size="14">阅读并同意《相关条款》</van-checkbox>-->
      <!--            </div>-->
      <div style="margin: 16px;">
        <van-button round
                    block
                    type="info"
                    native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { getZpList, fangdai, getloadselect } from '@/api/public'
import { Toast } from 'vant';

export default {
  name: 'fangdai',
  data () {
    return {
      formData: {
        Name: '',
        Mobile: '',
        TownId: '',
        villageName: '',
        DotId: '',
        SysUserId: '',
      },
      position: '',
      aggree: false,
      streetText: '',
      showPickerStreet: false,
      columnsStreet: [],
      dotText: '',
      dotShowPicker: false,
      dotColumns: [],
      sysUserText: '',
      sysUserShowPicker: false,
      sysUserColumns: [{ text: '请先选择受理网点', disabled: true },],
    }
  },
  created () {
    debugger;
    this.getZpList();
    this.getloadselect();
  },
  methods: {
    getZpList () {
      getZpList().then((res) => {
        this.columnsStreet = res.data;
        this.columnsStreet.forEach((town) => {
          town.children = town.street;
          town.text = town.townName;
          town.street.forEach((street) => {
            street.text = street.streetName;
          })
        })
      }).catch(e => {
        console.log(e);
      })
    },
    getloadselect () {
      getloadselect().then((res) => {
        this.dotColumns = res.data.list;
        this.dotColumns.forEach((dot) => {
          dot.text = dot.dotName;
          dot.users.forEach((user) => {
            user.text = user.name;
          });
        });
      }).catch(e => {
        console.log(e);
      })
    },
    onstreetConfirm (value, index) {
      this.streetText = value[0] + '-' + value[1];
      this.formData.TownId = this.columnsStreet[index[0]].street[index[1]].streetId;
      this.showPickerStreet = false;
    },
    dotConfirm (value, index) {
      this.dotText = value.text;
      this.formData.DotId = value.id;
      this.sysUserColumns = this.dotColumns[index].users;
      this.position = this.dotColumns[index].address;
      this.dotShowPicker = false;
    },
    sysUserConfirm (value) {
      this.sysUserText = value.text;
      this.formData.SysUserId = value.id;
      this.sysUserShowPicker = false;
    },
    onSubmit () {
      fangdai(this.formData).then((res) => {
        Toast.success(res.msg);
        this.$router.push({
          path: '/index',
        });
      }).catch(e => {
        console.log(e);
      })
    },
    mobileValidator (val) {
      return /^1(3|4|5|7|8|9|6)\d{9}$/i.test(val);
    },
    nameValidator (val) {
      return /^[\u4e00-\u9fa5]{2,5}$/.test(val);
    },
  }
}
</script>
<style lang="less">
.fangdai {
  .van-field__error-message {
    text-align: right;
  }
}
</style>


